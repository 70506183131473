import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseApp from "../../../firebase.config"; // Import initialized Firebase app
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  UserCredential,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// Define the shape of the location state, including optional email
interface LocationState {
  email?: string;
}

export default function PasswordLogin() {
  const auth = getAuth(firebaseApp); // Initialize Firebase Auth
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserCredential["user"] | null>(null);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Extract email from location state or use a fallback value
  const email = (location.state as LocationState)?.email || "";

  useEffect(() => {
    // Redirect to login if no email is provided
    if (!email) {
      redirectToLogin(email);
    }

    // Set persistence explicitly (optional)
    setPersistence(auth, browserLocalPersistence).catch((error) =>
      console.error("Error setting persistence:", error)
    );

    // Google sign in redirect result
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;

          // The signed-in user info.
          const user = result.user;
          setUser(user); // Set the user in state

          // Navigate to home page after successful Google login
          navigate("/"); // Redirect to home page
        }
      })
      .catch((error) => {
        console.error("Error during redirect result:", error);
      });
  }, [auth]);

  // Handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent form's default behavior
    handlePasswordLogin(email, password); // Call the email check function
  };

  // Handle password login
  const handlePasswordLogin = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (!user.emailVerified) {
          redirectToVerifyEmail(email);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  // Function to handle Google sign-in
  const handleGoogleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      login_hint: email,
    });

    signInWithRedirect(auth, provider);
  };

  // Handle account creation for a new email
  const redirectForgotPassword = () => {
    // Redirect to account creation page, or show account creation form
    navigate("/forgotpassword", {
      state: { email },
    });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Redirect to login page
  const redirectToLogin = (email: string) => {
    navigate("/login", {
      state: { email },
    });
  };

  // Redirect to verify email page
  const redirectToVerifyEmail = (email: string) => {
    navigate("/verifyemail", {
      state: { email },
    });
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold tracking-tight leading-9 [word-spacing:2px] text-gray-900">
              Welcome back!
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              // action="#" method="POST"
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div>
                <label className="block text-sm font-semibold font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2 flex items-center justify-left">
                  <span className="text-gray-900">{email}</span>
                  <button
                    type="button"
                    onClick={() => redirectToLogin(email)}
                    className="ml-4 text-sm underline text-gray-900 hover:text-blue-500 hover:no-underline px-2 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300"
                  >
                    Change
                  </button>
                </div>
              </div>
              <div className=" divide-y divide-gray-200">
                <div></div>
                <div></div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <button
                      type="button"
                      className="font-semibold text-blue-500 hover:text-gray-900 hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300"
                      onClick={redirectForgotPassword}
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Toggle password visibility
                    value={password} // Controlled input
                    onChange={(e) => setPassword(e.target.value)} // Update password state on change
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-3 px-2 pr-9 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility} // Handle visibility toggle
                    className="absolute inset-y-0 right-0 flex items-center pr-1"
                  >
                    <div className="rounded-full hover:bg-gray-200 py-1 px-1.5 flex items-center justify-center">
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon
                          sx={{
                            fontSize: 20,
                          }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          sx={{
                            fontSize: 20,
                          }}
                        />
                      )}
                    </div>
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-sky-400 hover:via-blue-700 hover:to-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                >
                  Log in
                </button>
              </div>
            </form>

            {/* <div className="flex items-center justify-center py-5">
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
              <span className="mx-2 text-md text-gray-500">or</span>
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
            </div> */}

            {/* Button for Google sign-in */}
            {/* <div>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="group flex w-full justify-center items-center rounded-full border border-gray-500 bg-white py-2 text-sm font-semibold leading-6 text-gray-900 hover:shadow-sm hover:bg-zinc-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  className="w-5 h-5 mx-2"
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
                Continue with Google
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
