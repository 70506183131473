import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseApp from "../../../firebase.config"; // Import initialized Firebase app
import { getAuth, sendPasswordResetEmail, UserCredential } from "firebase/auth";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

// Define the shape of the location state, including optional email
interface LocationState {
  email?: string;
}

export default function ForgotPassword() {
  const auth = getAuth(firebaseApp); // Initialize Firebase Auth
  const location = useLocation();
  const navigate = useNavigate();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Extract email from location state or use a fallback value
  const email = (location.state as LocationState)?.email || "";

  useEffect(() => {
    // Redirect to login if no email is provided
    if (!email) {
      redirectToLogin(email);
    }
  }, [email]);

  // Handle form submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent form's default behavior
    handleForgotPassword(email);
  };

  // Handle password login
  const handleForgotPassword = (email: string) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // On success, set submitted to true and clear any errors
        setHasSubmitted(true);
        setError(null);
      })
      .catch((error) => {
        // On error, set the error message and keep submitted as false
        setError("Failed to send password reset email. Please try again.");
        setHasSubmitted(false);
      });
  };

  // Redirect to login page
  const redirectToLogin = (email: string) => {
    navigate("/login", {
      state: { email },
    });
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold tracking-tight leading-9 [word-spacing:2px] text-gray-900">
              Forgot your password?
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            {!hasSubmitted ? (
              <form
                // action="#" method="POST"
                onSubmit={handleSubmit}
                className="space-y-6"
              >
                <div>
                  <label className="block text-sm font-semibold font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2 flex items-center justify-left">
                    <span className="text-gray-900">{email}</span>
                    <button
                      type="button"
                      onClick={() => redirectToLogin(email)}
                      className="ml-4 text-sm underline text-gray-900 hover:text-blue-500 hover:no-underline px-2 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300"
                    >
                      Change
                    </button>
                  </div>
                </div>

                <div className=" divide-y divide-gray-200">
                  <div></div>
                  <div></div>
                </div>
                <div className="mt-8 text-left text-sm text-gray-900">
                  <p>
                    We'll send you an email with a link to reset your password.{" "}
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-sky-400 hover:via-blue-700 hover:to-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                  >
                    Continue
                  </button>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <h3 className="flex items-center justify-center text-xl font-semibold text-gray-900">
                  <CheckCircleOutlineOutlinedIcon className="text-green-500" />
                  <span className="ml-1">Success!</span>
                </h3>
                <p className="mt-4 text-md text-gray-900">
                  A password reset link has been sent to{" "}
                  <strong>{email}</strong>. Please check your inbox.
                </p>
                <p className="mt-4 text-sm text-gray-600">
                  If you haven't received an email, check your spam folder or
                  try again after a few minutes.
                </p>
                <button
                  onClick={() => redirectToLogin(email)}
                  className="mt-6 group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-sky-400 hover:via-blue-700 hover:to-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                >
                  Go to Login
                </button>
              </div>
            )}
            {error && (
              <div className="mt-4 text-center text-sm text-red-500">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
