import * as React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import AuthHome from "./components/Auth/AuthHome";
import PasswordLogin from "./components/Auth/PasswordLogin";
import SignUp from "./components/Auth/SignUp";
import ForgotPassword from "./components/Auth/ForgotPassword";
import VerifyEmail from "./components/Auth/VerifyEmailPage";

export default function App(): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<AuthHome />} />
        <Route path="/signin" element={<AuthHome />} />
        <Route path="/signup" element={<AuthHome />} />

        {/* These routes need to be protected (only accessible through login/signup flow) */}
        <Route path="/signup/user" element={<SignUp />} />
        <Route path="/login/user" element={<PasswordLogin />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
      </Routes>
    </Router>
  );
}
