import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseApp from "../../../firebase.config"; // Import initialized Firebase app
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  UserCredential,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

// Define the shape of the location state, including optional email
interface LocationState {
  email?: string;
}

export default function SignUp() {
  const auth = getAuth(firebaseApp); // Initialize Firebase Auth
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserCredential["user"] | null>(null);
  const [password, setPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const [shakeError, setShakeError] = useState<boolean>(false); // State for shaking animation
  const [isPasswordTooSmall, setIsPasswordTooSmall] = useState<boolean>(false);
  const [isPasswordTooLarge, setIsPasswordTooLarge] = useState<boolean>(false);
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false);

  // Extract email from location state or use a fallback value
  const email = (location.state as LocationState)?.email || "";

  useEffect(() => {
    // Redirect to login if no email is provided
    if (!email) {
      redirectToLogin(email);
    }

    // Google sign in redirect result
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;

          // The signed-in user info.
          const user = result.user;
          setUser(user); // Set the user in state

          // Navigate to home page after successful Google login
          navigate("/"); // Redirect to home page
        }
      })
      .catch((error) => {
        console.error("Error during redirect result:", error);
      });
  }, [auth]);

  // Handle form submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent form's default behavior

    // Check if the email is already in use
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    if (signInMethods.length == 0) {
      if (password.length < 8) {
        setIsPasswordTooLarge(false);
        setIsPasswordTooSmall(true);
        setHasSubmitted(true); // Set this when the user attempts to submit
        setShakeError(true); // Trigger the shaking animation
        setTimeout(() => setShakeError(false), 500); // Reset the shaking effect after 500ms
        return;
      } else if (password.length > 4000) {
        setIsPasswordTooSmall(false);
        setIsPasswordTooLarge(true);
        setHasSubmitted(true); // Set this when the user attempts to submit
        setShakeError(true); // Trigger the shaking animation
        setTimeout(() => setShakeError(false), 500); // Reset the shaking effect after 500ms
        return;
      }

      handleCreateAccount(email, password); // Call the email check function
    } else {
      setUserAlreadyExists(true);
    }
  };

  // Handle account creation
  const handleCreateAccount = (email: string, password: string) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        redirectToVerifyEmail(email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error creating user:", error.code, error.message);
      });
  };

  // Function to handle Google sign-in
  const handleGoogleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      login_hint: email,
    });

    signInWithRedirect(auth, provider);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Redirect to login page
  const redirectToLogin = (email: string) => {
    navigate("/login", {
      state: { email },
    });
  };

  // Redirect to verify email page
  const redirectToVerifyEmail = (email: string) => {
    navigate("/verifyemail", {
      state: { email },
    });
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight [word-spacing:2px] text-gray-900">
              Create your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              // action="#" method="POST"
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div>
                <label className="block text-sm font-semibold font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2 flex items-center justify-left">
                  <span className="text-gray-900">{email}</span>
                  <button
                    type="button"
                    onClick={() => redirectToLogin(email)}
                    className="ml-4 text-sm underline text-gray-900 hover:text-blue-500 hover:no-underline px-2 py-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300"
                  >
                    Change
                  </button>
                </div>
              </div>
              <div className=" divide-y divide-gray-200">
                <div></div>
                <div></div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Create Password <span className="text-red-600">*</span>
                  </label>
                </div>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Toggle password visibility
                    value={password} // Controlled input
                    onChange={(e) => setPassword(e.target.value)} // Update password state on change
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-3 px-2 pr-9 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility} // Handle visibility toggle
                    className="absolute inset-y-0 right-0 flex items-center pr-1"
                  >
                    <div className="rounded-full hover:bg-gray-200 py-1 px-1.5 flex items-center justify-center text-gray-900">
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon
                          sx={{
                            fontSize: 20,
                          }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          sx={{
                            fontSize: 20,
                          }}
                        />
                      )}
                    </div>
                  </button>
                </div>

                {/* Password requirement enforcement text */}
                <div className="flex items-center mt-2">
                  {/* Password too small error*/}
                  {hasSubmitted && isPasswordTooSmall && !userAlreadyExists && (
                    <>
                      <ErrorOutlineOutlinedIcon
                        sx={{
                          fontSize: 20,
                        }}
                        className="text-red-500 mr-2"
                      />
                      <p
                        className={`text-sm ${
                          shakeError
                            ? "animate-shake text-red-500"
                            : "text-red-500"
                        }`}
                      >
                        Use 8 characters or more for your password.
                      </p>
                    </>
                  )}
                  {/* Password too large error*/}
                  {hasSubmitted && isPasswordTooLarge && (
                    <>
                      <ErrorOutlineOutlinedIcon
                        sx={{
                          fontSize: 20,
                        }}
                        className="text-red-500 mr-2"
                      />
                      <p
                        className={`text-sm ${
                          shakeError
                            ? "animate-shake text-red-500"
                            : "text-red-500"
                        }`}
                      >
                        Use 4000 characters or less for your password.
                      </p>
                    </>
                  )}
                </div>
                <div className="flex items-center mt-2">
                  {/* User already exists error*/}
                  {userAlreadyExists && (
                    <>
                      <ErrorOutlineOutlinedIcon
                        sx={{
                          fontSize: 20,
                        }}
                        className="text-red-500 mr-2"
                      />
                      <p
                        className={`text-sm ${
                          shakeError
                            ? "animate-shake text-red-500"
                            : "text-red-500"
                        }`}
                      >
                        This account already exists.{" "}
                        <button
                          className="text-red-500 underline rounded-full px-2 hover:no-underline hover:bg-red-100"
                          onClick={() => redirectToVerifyEmail(email)}
                        >
                          Verify your email
                        </button>
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-sky-400 hover:via-blue-700 hover:to-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                >
                  Create account
                </button>
              </div>
            </form>
            <div className="flex items-center justify-center py-5">
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
              <span className="mx-2 text-md text-gray-500">or</span>
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
            </div>

            {/* Button for Google sign-in */}
            <div>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="group flex w-full justify-center items-center rounded-full border border-gray-500 bg-white py-2 text-sm font-semibold leading-6 text-gray-900 hover:shadow-sm hover:bg-zinc-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  className="w-5 h-5 mx-2"
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
