import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseApp from "../../../firebase.config";
import { getAuth, User, sendEmailVerification } from "firebase/auth";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CircularProgress from "@mui/material/CircularProgress"; // Optional if you want a different spinner style from MUI
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import { EmailRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

interface LocationState {
  email?: string;
}

export default function VerifyEmail() {
  const auth = getAuth(firebaseApp);
  const location = useLocation();
  const navigate = useNavigate();

  const [firstEmailSent, setFirstEmailSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false); // Check if user is verified
  const [loading, setLoading] = useState(true); // Loading spinner state
  const [error, setError] = useState<string | null>(null);
  const [canResend, setCanResend] = useState(false); // Track when user can resend
  const [resendLoading, setResendLoading] = useState(false); // Show loading when resending
  const [open, setOpen] = useState(true);

  const email = (location.state as LocationState)?.email || "";

  useEffect(() => {
    if (!email) {
      redirectToLogin(email);
    }

    // If user is verified, pop up confirmation
    const user: User | null = auth.currentUser;
    if (user && user.emailVerified) {
      setIsVerified(true);
      setLoading(false); // Stop the spinner
    }

    // Send initial email on page load
    if (!firstEmailSent) {
      handleResendEmail();
      setFirstEmailSent(true);
    }

    // Set an interval to check if the user has verified their email
    const interval = setInterval(() => {
      auth.currentUser
        ?.reload()
        .then(() => {
          const user: User | null = auth.currentUser;
          if (user && user.emailVerified) {
            setIsVerified(true);
            setLoading(false); // Stop the spinner
            clearInterval(interval); // Stop checking once verified
          }
        })
        .catch((error) => {
          setError("Error checking verification status.");
        });
    }, 3000); // Check every 3 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, [auth, email]);

  useEffect(() => {
    // Allow resending email after seconds
    const timer = setTimeout(() => {
      setCanResend(true);
    }, 60000); // 60 sec

    return () => clearTimeout(timer);
  }, []);

  // Handle resend verification email
  const handleResendEmail = async () => {
    setResendLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);

        setCanResend(false); // Disable resend until after another delay
        setTimeout(() => {
          setCanResend(true);
          setResendLoading(false);
        }, 60000); // Enable again after 60 seconds
      }
    } catch (err) {
      console.error("Error resending email verification:", err);
      setError("Failed to resend verification email.");
      setResendLoading(false);
    }
  };

  // Redirect to login page
  const redirectToLogin = (email: string) => {
    navigate("/login", {
      state: { email },
    });
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold tracking-tight leading-9 [word-spacing:2px] text-gray-900">
              Verify your email
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="text-center">
              <h3 className="flex items-center justify-center text-xl font-semibold text-gray-900">
                <EmailRounded
                  className="px-1 text-green-500 outline outline-2 shadow-sm rounded-lg"
                  sx={{ fontSize: 30 }}
                />
                <span className="ml-2">Email verification sent!</span>
              </h3>
              <p className="mt-4 text-md text-gray-900">
                An email verification link has been sent to{" "}
                <strong>{email}</strong>. Please check your inbox.
              </p>
              <p className="mt-4 text-sm text-gray-600">
                If you haven't received an email, check your spam folder or try
                resending the email after a few minutes.
              </p>

              {loading ? (
                <>
                  <div className="flex mt-4 justify-center items-center">
                    <React.Fragment>
                      <svg width={0} height={0}>
                        <defs>
                          <linearGradient
                            id="my_gradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stopColor="#0ea5e9" />
                            <stop offset="100%" stopColor="#3b82f6" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <CircularProgress
                        size="25px"
                        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
                      />
                    </React.Fragment>
                  </div>
                  <span className="mt-10 text-sm">
                    Waiting for verification
                  </span>
                </>
              ) : (
                <Dialog
                  open={open}
                  onClose={() => setOpen(true)}
                  className="relative z-10"
                >
                  {/* Backdrop */}
                  <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                  />
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-start justify-center py-80 text-center sm:items-center sm:p-0">
                      <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-sm"
                      >
                        <div className="bg-white px-4 py-6 sm:p-6">
                          {/* Icon */}
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <CheckCircleOutlineOutlinedIcon
                              aria-hidden="true"
                              className="h-6 w-6 text-green-600"
                              sx={{ fontSize: 30 }}
                            />
                          </div>
                          {/* Title */}
                          <DialogTitle
                            as="h3"
                            className="mt-3 text-center text-lg font-semibold leading-6 text-gray-900"
                          >
                            Email verified!
                          </DialogTitle>
                          {/* Body Text */}
                          <div className="mt-2 text-center">
                            <p className="text-sm text-gray-500">
                              Your email address was successfully verified.
                            </p>
                          </div>
                        </div>
                        {/* Button */}
                        <div className="bg-gray-50 px-4 py-4 sm:px-6">
                          <div className="flex justify-center">
                            <button
                              onClick={() => redirectToLogin(email)}
                              disabled={!isVerified}
                              className="flex w-full justify-center rounded-full py-2 text-sm font-semibold leading-6 text-white shadow-sm bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 hover:from-sky-400 hover:via-blue-700 hover:to-sky-400"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </DialogPanel>
                    </div>
                  </div>
                </Dialog>
              )}

              {/* Resend Email Button */}
              <div className="flex mt-5 justify-center items-center">
                <button
                  onClick={handleResendEmail}
                  disabled={!canResend || resendLoading}
                  className={`flex w-3/5 justify-center items-center text-center rounded-full py-2 text-sm font-semibold leading-6 text-white shadow-sm ${
                    canResend && !resendLoading
                      ? "bg-blue-500 hover:bg-blue-600"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                >
                  {resendLoading
                    ? "Email sent..."
                    : "Resend Verification Email"}
                </button>
              </div>
            </div>

            {error && (
              <div className="mt-4 text-center text-sm text-red-500">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
