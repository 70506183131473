import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import firebaseApp from "../../../firebase.config"; // Import initialized Firebase app
import {
  getAuth,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  UserCredential,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth"; // Import necessary Firebase auth methods

// Define the types for Firebase user and location state
interface LocationState {
  email?: string;
}

export default function AuthHome() {
  const auth = getAuth(firebaseApp); // Initialize Firebase Auth
  const navigate = useNavigate();
  const location = useLocation();

  // State to manage user login info
  const [user, setUser] = useState<UserCredential["user"] | null>(null);
  const [emailExists, setEmailExists] = useState<boolean | null>(null);
  const [email, setEmail] = useState<string>(
    (location.state as LocationState)?.email || ""
  );

  useEffect(() => {
    // Set persistence explicitly (optional)
    setPersistence(auth, browserLocalPersistence).catch((error) =>
      console.error("Error setting persistence:", error)
    );

    // Handle the redirect result from Google login
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;

          // The signed-in user info.
          const user = result.user;
          setUser(user); // Set the user in state

          // Navigate to home page after successful Google login
          navigate("/"); // Redirect to home page
        }
      })
      .catch((error) => {
        console.error("Error during redirect result:", error);
      });

    // Set the user if logged in, null if not
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [auth, navigate]);

  // Separate effect to handle redirection when user state changes
  useEffect(() => {
    if (user) {
      navigate("/"); // Redirect to home page if user is logged in
    }
  }, [user, navigate]); // Run when user state changes

  // Handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent form's default behavior
    checkIfEmailExists(email); // Call the email check function
  };

  // Function to check if email exists
  const checkIfEmailExists = async (email: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      // alert("Sign-in methods for email: " + signInMethods); // Add this for debugging
      setEmailExists(signInMethods.length > 0); // If there are sign-in methods, the email exists
      if (signInMethods.length > 0) {
        redirectLogin(email, signInMethods);
      } else {
        redirectCreateAccount(email);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  /*
  
  POTENTIAL ISSUE: 
    if a user creates account using password
      then later decides to sign in with google using same email
        the accounts will merge (currently allowed)

      Solution: email verification before allowing use 
                for password login account should be good
    
  */
  // Handle login for existing email
  const redirectLogin = (email: string, signInMethods: string[]) => {
    if (signInMethods.includes(GoogleAuthProvider.PROVIDER_ID)) {
      // Prioritize Google sign-in if available
      handleGoogleSignIn();
    } else if (signInMethods.includes(EmailAuthProvider.PROVIDER_ID)) {
      // Otherwise, handle email/password login
      navigate("/login/user", { state: { email } });
    }
  };

  // Handle account creation for a new email
  const redirectCreateAccount = (email: string) => {
    // Redirect to account creation page, or show account creation form
    navigate("/signup/user", {
      state: { email },
    });
  };

  // Function to handle Google sign-in
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      login_hint: email,
    });

    signInWithRedirect(auth, provider);
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
            <a href="/" className="inline-block ">
              <img
                alt="Your Company"
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
                className="mx-auto h-10 w-auto"
              />
            </a>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight [word-spacing:2px] text-gray-900">
              Log in or create your account
            </h2>
          </div>

          <p className="mt-8 text-center text-sm text-gray-500">
            Not sure if you have an account?{" "}
          </p>
          <p className="text-center text-sm text-gray-600">
            Enter your email and we'll check for you.{" "}
          </p>

          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              // action="#" method="POST"
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email} // Controlled input
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-3 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-blue-500 hover:via-blue-500 hover:to-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                >
                  Continue{"  "}
                  <span
                    aria-hidden="true"
                    className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-1.5"
                  >
                    &rarr;
                  </span>
                </button>
              </div>
            </form>
            <div className="flex items-center justify-center py-5">
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
              <span className="mx-2 text-md text-gray-500">or</span>
              <div className="flex-1 border-t border-dashed border-gray-500"></div>
            </div>

            {/* Button for Google sign-in */}
            <div>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="group flex w-full justify-center items-center rounded-full border border-gray-500 bg-white py-2 text-sm font-semibold leading-6 text-gray-900 hover:shadow-sm hover:bg-zinc-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  className="w-5 h-5 mx-2"
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
