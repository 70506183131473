import React, { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth"; // Import Firebase Auth
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import LogoutIcon from "@mui/icons-material/Logout";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [user, setUser] = useState<User | null>(null); // Track if user is logged in

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Simulating cart items (replace with your actual cart logic)
    setCartItemCount(3);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  // Firebase Auth to track if the user is logged in
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && currentUser.emailVerified) {
        setUser(currentUser); // Set the user only if verified
      } else {
        handleSignOut(); // Sign out if not verified
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  // Function to handle user sign out
  const handleSignOut = () => {
    const auth = getAuth(); // Get auth instance
    signOut(auth)
      .then(() => {
        setUser(null); // Clear user state on successful sign out
      })
      .catch((error) => {
        console.error("Error signing out:", error); // Handle errors if any
      });
  };

  return (
    <div className="bg-white">
      <header
        className={`fixed inset-x-0 top-0 z-50 transition-all duration-300 ${
          scrolled ? "bg-white/70 backdrop-blur-md shadow-md" : "bg-transparent"
        }`}
      >
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden gap-6">
            {/* Shopping Cart Icon with indicator MOBILE */}
            <a href="#" className="text-gray-900 relative">
              <span>{user?.email}</span>
              <ShoppingCartOutlinedIcon
                className="h-6 w-6"
                sx={{
                  fontSize: 24,
                }}
              />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-200 text-gray-900 text-xs font-bold border-2 border-blue-600 rounded-full rounded-tr-lg h-5 w-5 flex items-center justify-center">
                  {cartItemCount}
                </span>
              )}
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center gap-6">
            {user ? (
              // Show Account button if user is logged in
              <>
                <Menu as="div" className="relative inline-block text-center">
                  <MenuButton
                    className="group mr-4 flex justify-center rounded-full outline outline-2 outline-blue-600 bg-blue-200 px-2 py-1 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                    // onClick={handleClick}
                  >
                    <PersonRoundedIcon
                      className=" text-gray-900"
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-md ring-1 ring-gray-400 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      <MenuItem>
                        <div className="flex justify-center">
                          <a
                            href="#"
                            className="flex w-4/5 my-2 px-1 py-2 justify-center items-center text-center text-sm text-gray-900 rounded-full border bg-gray-100 border-gray-400 hover:bg-gray-200 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                          >
                            <PersonRoundedIcon
                              className="mr-2 text-gray-900 outline outline-offset-1 rounded-full"
                              sx={{
                                fontSize: 12,
                              }}
                            />
                            <span className="">My Account </span>
                          </a>
                        </div>
                      </MenuItem>
                      <div className="px-2 divide-y divide-gray-300 divide-dashed ">
                        <div></div>
                        <div></div>
                      </div>
                      <MenuItem>
                        <div className="flex justify-center">
                          <button
                            className="flex w-4/5 my-2 px-2 py-2 justify-center items-center text-center text-sm text-white rounded-full bg-blue-500 hover:bg-blue-600 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            onClick={handleSignOut}
                          >
                            <LogoutIcon
                              className="mr-1 text-white"
                              sx={{
                                fontSize: 16,
                              }}
                            />
                            <span className="">Sign out</span>
                          </button>
                        </div>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </>
            ) : (
              // Show Log in / Sign up if no user is logged in
              <a
                href="/login"
                className="group flex justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-blue-500 hover:via-blue-500 hover:to-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
              >
                Log in <span className="mx-2">/</span> Sign up{" "}
                <span
                  aria-hidden="true"
                  className="inline-block ml-1 transition-transform duration-300 group-hover:translate-x-1"
                >
                  &rarr;
                </span>
              </a>
            )}
            {/* Shopping Cart Icon with indicator */}
            <a href="#" className="text-gray-900 relative mr-2">
              <ShoppingCartOutlinedIcon
                className="h-6 w-6"
                sx={{
                  fontSize: 26,
                }}
              />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-200 text-gray-900 text-xs font-bold border-2 border-blue-600 rounded-full rounded-tr-lg h-5 w-5 flex items-center justify-center">
                  {cartItemCount}
                </span>
              )}
            </a>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://tailwindui.com/plus/img/logos/mark.svg?color=blue&shade=500"
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  {user ? (
                    // Show Account button if user is logged in
                    <div>
                      <div>
                        <button
                          className="group flex w-full justify-center items-center rounded-full px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-100 border border-gray-400 hover:bg-gray-200"
                          // onClick={handleSignOut}
                        >
                          <PersonRoundedIcon
                            className="mr-3 text-gray-900 outline outline-offset-1 rounded-full"
                            sx={{
                              fontSize: 18,
                            }}
                          />
                          <span className="text-base text-gray-900">
                            Account
                          </span>
                        </button>
                      </div>
                      <div>
                        <button
                          className="group flex w-full mt-4 justify-center items-center rounded-full bg-blue-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600"
                          onClick={handleSignOut}
                        >
                          <LogoutIcon
                            className="mr-2 text-white rounded-full"
                            sx={{
                              fontSize: 20,
                            }}
                          />
                          <span className="text-base">Sign out</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <a
                      href="/login"
                      className="group flex w-full justify-center rounded-full bg-gradient-to-r from-sky-500 via-blue-600 to-sky-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:from-blue-500 hover:via-blue-500 hover:to-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
                    >
                      Log in <span className="mx-6">/</span> Sign up
                    </a>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
}
