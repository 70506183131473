import * as React from "react";
import Header from "../Header/Header";
import HeroSection from "../HeroSection/HeroSection";

export default function Home(): JSX.Element {
  return (
    <>
      <Header />
      <HeroSection />
    </>
  );
}
